import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/Users/vincepicone/Programming/Work/carbon-website/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Caption = makeShortcode("Caption");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "color"
    }}>{`Color`}</h2>
    <h3 {...{
      "id": "primary-button"
    }}>{`Primary button`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Class`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Property`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Color token`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--btn--primary`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`text color`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$text-04`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--btn__icon`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`svg`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$icon-03`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--btn--primary`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`background-color`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$interactive-01`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`:hover`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`background-color`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$hover-primary`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`:active`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`background-color`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$active-primary`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`:focus`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`border`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$focus`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`:disabled`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`background-color`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$disabled-02`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`:disabled`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`text color`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$disabled-03`}</inlineCode></td>
        </tr>
      </tbody>
    </table>
    <h3 {...{
      "id": "secondary-button"
    }}>{`Secondary button`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Class`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Property`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Color token`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--btn--secondary`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`text color`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$text-04`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--btn__icon`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`svg`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$icon-03`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--btn--secondary`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`background-color`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$interactive-02`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--btn--secondary`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`border`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$interactive-02`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`:hover`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`background-color`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$hover-secondary`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`:active`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`background-color`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$active-secondary`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`:focus`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`border`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$focus`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`:disabled`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`background-color`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$disabled-02`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`:disabled`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`text color`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$disabled-03`}</inlineCode></td>
        </tr>
      </tbody>
    </table>
    <h3 {...{
      "id": "tertiary-button"
    }}>{`Tertiary button`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Class`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Property`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Color token`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--btn--tertiary`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`text color`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$interactive-03`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--btn__icon`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`svg`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$interactive-03`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--btn--tertiary`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`background-color`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`transparent`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--btn--tertiary`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`border`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$interactive-03`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`:hover`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`text color`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$text-04`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`:hover`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`svg`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$icon-03`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`:hover`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`background-color`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$hover-tertiary`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`:active`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`background-color`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$active-tertiary`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`:focus`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`border`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$focus`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`:disabled`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`background-color`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$disabled-02`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`:disabled`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`text color`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$disabled-03`}</inlineCode></td>
        </tr>
      </tbody>
    </table>
    <h3 {...{
      "id": "ghost-button"
    }}>{`Ghost button`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Class`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Property`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Color token`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--btn--ghost`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`text color`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$link-01`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--btn__icon`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`svg`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$link-01`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--btn--ghost`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`background-color`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`–`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`:hover`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`text color`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$hover-primary-text`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`:hover`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`svg`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$hover-primary-text`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`:hover`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`background-color`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$hover-ui`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`:active`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`background-color`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$active-ui`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`:focus`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`border`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$focus`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`:disabled`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`text color`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$disabled-03`}</inlineCode></td>
        </tr>
      </tbody>
    </table>
    <h3 {...{
      "id": "danger-button"
    }}>{`Danger button`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Class`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Property`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Color token`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--btn--danger--primar`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`text color`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$text-04`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--btn__icon`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`svg`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$icon-03`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--btn--danger--primary`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`background-color`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$support-01`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`:hover`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`background-color`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$hover-danger`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`:active`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`background-color`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$active-danger`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`:focus`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`border`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$focus`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`:disabled`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`background-color`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$disabled-02`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`:disabled`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`text color`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$disabled-03`}</inlineCode></td>
        </tr>
      </tbody>
    </table>
    <div className="image--fixed">
      <span {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1152px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "68.07065217391305%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAYAAAAvxDzwAAAACXBIWXMAAAsSAAALEgHS3X78AAADa0lEQVQ4y42UbUxTVxiA3/YWxAKTCsuiUbc/ayQLIBhEiv2A2tIPpEw+RAtoNPGHWyaIMW4u+7OIJqCGOBPd9mPLklETIYUmXbj9ZCKmBH5hgpTPwC04sfT2FhAFOb69RaOJW/YmT95zzz3nOSf3vOfC0NAQ+Hw+mJqagtHRUR6GYYCmabDb7Xzb7/fD2NgYDA8PAyEEent7+f7x8XF+fPRdNMeikgigllBQh5iRCqSKCCEH+3OxLZ+nYH+QgmzM0rDwx1t2gcdFU9bOLspms1G46BtiPnIfBPCBwI38V3xwTkdHB0Da2RXFjvMvbu+6sNqy/fzLa5JvXv60tX7tQmJ14IDkGHMtrXbucsqx2aaEykALlDypv2Pplbmd9HWadjQ5nc4mt9t9FfkBEfPW5HOkZtt3ZHrHJTL0yUXyKLGBTCQ1kk5h9dLxRHNkJuV4xC82R/xQHp4B47L9tuVhndtFM900PeJwOPwomnC5XIOIhBeq8jN3ynIzi/P2ZhQV7MsqKszP0CrzvsgrMWh26Yq1Oo1Gq9HrijVlh3Q6rVoha2v7c2dPT0+xx+M5GAVFGpSqkDheqFQqvjIaDcRo0C8Z9PpllUpJCmSyQVOJ7szRqsOr5qMVrLm6gj1sMq5qihQ+S1vbGa/XS7q7u5exEp6jcBVlc5jTeOG+wnKJ/KApXakxSTFL9xeWpecpdJ/uKb2ybU9ZizyrrKUgG9n7ZbM83XAl587v1lSvx73b4XBK8RtKUbQbhZ9jpmJH00g2QQNJgbNEAt8S8ZvjpapCp7fUhUnaidDiRzULS1AeXBNWLIxlfD29nXB3xZ1//Z2KO02KjkWZCKVCfqLYPCHaeqQv/uMqT5yk2hefVDmwSf29TQjqwGegZUygDxhBzRhBOVMKSkatanwkXme6RO02T7zVao1DUTwKEzBvlNIp3NFJshkRIxQhQUGsDiMlaytc/4tlzkNeRbz43IfcQ1Ju3Pw17mHfg+SRkRHxu3WIUojKYEOWiIgIecoLnz7j5FOB8B+j0+zPc/PcLwss91swxDXPB9nkG9ebRf39/al43SqjYwcGBgSTk5P/fg1YNgz/J0KhUEI0t7a2wvr6+kZvbIdvIYTmu58tcILhSZYafByiZv8JU4uLEYrjIkKWZfkfR3t7Oz/OYrG8t8hr94/PwZ8gFjcAAAAASUVORK5CYII=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Example of a normal primary button",
          "title": "Example of a normal primary button",
          "src": "/static/a427dedb66091b68255621dbeb418116/fb070/button-style-1.png",
          "srcSet": ["/static/a427dedb66091b68255621dbeb418116/d6747/button-style-1.png 288w", "/static/a427dedb66091b68255621dbeb418116/09548/button-style-1.png 576w", "/static/a427dedb66091b68255621dbeb418116/fb070/button-style-1.png 1152w", "/static/a427dedb66091b68255621dbeb418116/c3e47/button-style-1.png 1472w"],
          "sizes": "(max-width: 1152px) 100vw, 1152px",
          "loading": "lazy"
        }}></img>{`
    `}</span>
    </div>
    <Caption mdxType="Caption">Primary, secondary, and ghost button state examples</Caption>
    <h2 {...{
      "id": "typography"
    }}>{`Typography`}</h2>
    <p>{`Button text should be set in sentence case, with only the first word in a phrase and any proper nouns capitalized.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Class`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Font-size (px/rem)`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Font-weight`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type style`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--btn`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`14 / 0.875`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Regular / 400`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$body-short-01`}</inlineCode></td>
        </tr>
      </tbody>
    </table>
    <h2 {...{
      "id": "structure"
    }}>{`Structure`}</h2>
    <p>{`A button cannot have any element or text within 16 pixels / 1 rem of its borders. For button groups, the primary button is positioned on the outside of the set, while the secondary button is positioned inside. For a button with a glyph, the space between the button label and the glyph must be greater than or equal to 16 pixels / 1 rem. This is to accommodate for instances where two or more buttons with glyphs appear together.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Class`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Property`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`px / rem`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Spacing token`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--btn--primary`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`height`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`48 / 3`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`–`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--btn--sm`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`height`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`32 / 2`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`–`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--btn__icon`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`size`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`16 x 16`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`–`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--btn`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`padding-left`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`16 / 1`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$spacing-05`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--btn`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`padding-right`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`64 / 1`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`–`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--btn--sm`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`padding-left`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`16 / 1`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$spacing-05`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--btn--sm`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`padding-right`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`64 / 4`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`–`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--btn__icon`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`margin-left, margin-right`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`16 / 1`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$spacing-05`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--btn--ghost`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`padding-left, padding-right`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`16 / 2`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$spacing-05`}</inlineCode></td>
        </tr>
      </tbody>
    </table>
    <div className="image--fixed">
      <span {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1152px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "30.978260869565222%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAGCAYAAADDl76dAAAACXBIWXMAAAsSAAALEgHS3X78AAABDElEQVQY02NgAIJL6qkMd9TiGWZpNTHgArMcpzD8xyWZjyRzEWgYScD9PwuD0X9WBisgnQGky/4zgsULkQw9p5HBMsd2Ig+ILZ3/s0u64v9zucr/V0WK/1/jzfv/lDf/fyNILstxjYRg7q9FwiX/9gkW/dvGlPHvMFPanwCQHFP6Xya4gac1sxh7nOaygthqGe+ilPO+zFDP/9wrl/O1Wyz7xyTprK8hILl4t21svhFnDKMijtpHhB+ztg67bKES/VgEJKcVdR+3l4GOZwZiJhCGiWkmPWfEIg8Bi9BCF+hChkkOMyCcyv9M3BX/mRka/jNrZrxlUU57x8JQDwmnGeZ9QNPQNBdD+asgNAB8UWVjNjHx/gAAAABJRU5ErkJggg==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Structure for a primary button",
          "title": "Structure for a primary button",
          "src": "/static/d5cee1a37183bf78e48ad946945c2053/fb070/button-style-7.png",
          "srcSet": ["/static/d5cee1a37183bf78e48ad946945c2053/d6747/button-style-7.png 288w", "/static/d5cee1a37183bf78e48ad946945c2053/09548/button-style-7.png 576w", "/static/d5cee1a37183bf78e48ad946945c2053/fb070/button-style-7.png 1152w", "/static/d5cee1a37183bf78e48ad946945c2053/c3e47/button-style-7.png 1472w"],
          "sizes": "(max-width: 1152px) 100vw, 1152px",
          "loading": "lazy"
        }}></img>{`
    `}</span>
    </div>
    <Caption mdxType="Caption">
  Structure measurements for small and regular primary button | px / rem
    </Caption>
    <div className="image--fixed">
      <span {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1152px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "63.17934782608696%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAYAAACpUE5eAAAACXBIWXMAAAsSAAALEgHS3X78AAACrUlEQVQ4y2MwOnWWofPyfwYQ6H31n0Gr8D+jSOp/Rv6C30y8Of8ZSy9A5C79/8+gWP+fUbjoNyNb1j8mr9X/Gf//h8jBaBRQdRqLIBSI195ixCWHYZjYsqUMZgtug9nWa86pcpc8tmLKfGvIlfNfizf3j57x4sv6IDn79ec15Rq+2IoV/zdlz/5voTLlrm3lxw1sIDnfAycRBlrvPczgt/Y7C4gduff9TLX2//9ly/7/lij995e36P9/r3WfjoLkwnZ/SJOp/X1CsPDfTras/wf1Z3xb8uL/DR6QXOnd1wgDva9sYYja/QXMzrrwSla5/o+pTOk/A6CBhozJP8ztlz/XZMADMLzMFv+fgTXlPziMmBL+q/Dm/nfgzftnzZ3939p0zkMfh1WX7EFyHDn/dURL/kYI5v8P1Ox7kei86mZk3K1THCC50GMXEAbyF/xhECv+B/aycNG/6fL1//8rVP77LlL0/6fNnD//Y7a8OASSY8/+GyVc/HcDb97/JXp9X1fHrH02ufX/Zy6QXMkdJC9LB/1n4M+DOJsv+78I0CA1ieL/ylx5/xV9up5olU46qYLPy/umLUMTaaoEU9wpvxkYpumxw8VXM7AjKxOZPF1Ae/oWFtaWXi7hKTNEbPoXgL1rUNwACkiEQsX1axnKbr8Hh2Ha1XtWWv0/xBjCHvEnbDlt17fmgA5QKVgu5dJdPbXJX6UFE26IZa097rB48io5sBzQsIsaaQgDTfbuZqh58AnMLr3/XF5r4m8eBu/nHE1z9mouWLpLCqau7fkbNu8b/5mqzaYyrS+ZLbh2+iZWkPiUq3cZLqqnYoZF4dXPqMkB6jIQOGBWihCXdmf4w6DBjKQOFRjv2Qk0DOLC3FvPGdSqgQbnQpS1vHwG11D64hnDvGkb4Poi/2PPqgCOnSByaO/tjQAAAABJRU5ErkJggg==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Structure for a primary button",
          "title": "Structure for a primary button",
          "src": "/static/14578a7ae1e95a368dff007c44e9b436/fb070/button-style-14.png",
          "srcSet": ["/static/14578a7ae1e95a368dff007c44e9b436/d6747/button-style-14.png 288w", "/static/14578a7ae1e95a368dff007c44e9b436/09548/button-style-14.png 576w", "/static/14578a7ae1e95a368dff007c44e9b436/fb070/button-style-14.png 1152w", "/static/14578a7ae1e95a368dff007c44e9b436/c3e47/button-style-14.png 1472w"],
          "sizes": "(max-width: 1152px) 100vw, 1152px",
          "loading": "lazy"
        }}></img>{`
    `}</span>
    </div>
    <Caption mdxType="Caption">Spacing measurements for various button types | px / rem</Caption>
    <h3 {...{
      "id": "recommended"
    }}>{`Recommended`}</h3>
    <p>{`The following specs are not built into any of the button components but are recommended by design as the proper distance between buttons.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Attribute`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Property`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`px / rem`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Spacing token`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`External: button`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`margin`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`1px`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`-`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Button pairings`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`margin-left, margin-right`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`–`}</td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      